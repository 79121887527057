import timeoutPromise from '@justpark/helpers/src/timeoutPromise/timeoutPromise';

const promises = [
  timeoutPromise(0).then(() => import('react-dom')),
  timeoutPromise(0).then(() => import('@apollo/client')),
  timeoutPromise(0).then(() => import('react-i18next')),
  timeoutPromise(0).then(() => import('@bugsnag/js')),
  timeoutPromise(0).then(() => import('@not-remix-run/react'))
];

Promise.all(promises).then(() => {
  import(
    /* webpackChunkName: "client" */
    /* webpackFetchPriority: "high" */
    /* webpackPreload: true */
    './client'
  );
});
